"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver($route, eventService) {
    var event = eventService.getCurrentEvent();
    var fpType = options.fpType,
      fields = options.fields,
      preferCachedListResponse = options.preferCachedListResponse;
    var currentRouteDescriptor = $route.current ? $route.current.$$route : {};
    var crudOpts = currentRouteDescriptor.crudOpts || {};

    // fall back to looking in the route's crudOpts if an fp_type was not supplied as an arg
    var _fpType = fpType || crudOpts.queryFpType;
    if (!_fpType) {
      throw Error('an fpType must be provided for this strategy');
    }
    return eventService.getEventDocsByType(event, _fpType, {
      fields: fields,
      cached: preferCachedListResponse
    });
  };
  resolver.$inject = ["$route", "eventService"];
  return resolver;
};