// Utils
import { clone, get, groupBy, memoize, isEmpty, isNil, isObject } from 'lodash';
import { capitalize, humanize } from 'libs/utils/string';

// Constants
import { DEFAULT_DATETIME_FORMAT } from 'libs/utils/constants';

const GENERATED_DEFAULT_VALUE_PLACEHOLDER = '{{bstg:random}}';

/**
 * @typedef {Object} ControlConfig
 * @property {boolean} noTip whether to show the tooltip
 * @property {boolean} noLabel whether to show the label
 * @property {object} $i18n the i18n framework
 * @property {*} value the current control value
 * @property {string} [context] the context of the control
 * @property {object} [event] the workspace's object
 * @property {object} [parentModel] an optional model to pass to the control
 * @property {object} [settings] the settings of the workspace
 */

/**
 * @typedef {Object} ParsedServerError
 * @property {string} detail the error message
 * @property {string} [field] the field that caused the error
 * @property {string} [idField] the field that identifies the ID of the object
 * @property {string} [id] the id of the object that caused the error
 */

/**
 * Sorts the given fields by orders
 *
 * @param {FieldDescriptor[]} fields the fields to sort
 *
 * @returns {Object} the grouped and sorted matrix
 */
export function sortAndGroupFields(fields, editing = false) {
    if (!editing) {
        fields = fields.filter(f => !f.hidden);
    }

    let i = Math.max(...fields.filter(f => f.order).map(f => f.order));
    fields.forEach(f => f.order = Number.isFinite(f.order) ? f.order : Number.parseFloat(f.order || ++i));
    fields.sort((a, b) => a.order - b.order);

    const categories = groupBy(fields, g => g.category || 'Others');
    const sortedCat = Object.keys(categories).sort((a, b) => {
        const a1 = a.toLocaleLowerCase();
        const b1 = b.toLocaleLowerCase();

        // General always on top, Others always on bottom
        if (a1 === 'general' || b1 === 'others') return -1;
        if (a1 === 'others' || b1 === 'general') return 1;

        // The rest, alphabetically
        return a.localeCompare(b);
    });

    const fieldsets = {};
    for (const cat of sortedCat) {
        const groups = groupBy(categories[cat], g => g.group);
        delete groups.undefined;

        for (const [groupName, groupFields] of Object.entries(groups)) {
            const replacementIndex = categories[cat].findIndex(f => f.field === groupFields[0].field && f.group === groupName);
            if (replacementIndex) {
                categories[cat][replacementIndex] = groups[groupName];
            }
        }

        categories[cat] = categories[cat].filter(f => !f.group);
        fieldsets[cat] = categories[cat];
    }

    return fieldsets;
}

/**
 * Filters fields by the given placement
 *
 * @param {FieldDescriptor[]} fields a list of field descriptors
 * @param {string} placement the position by which filtering the fields with
 *
 * @return {object[]} a new list of fields filtered by placement
 */
export const fieldsByPlacement = memoize(
    (fields = [], placement = 'left') => fields.filter(field => getFieldPlacement(field) === placement),
    (fields, placement) => placement + JSON.stringify(fields || {})
);

/**
 * Given a field descriptor this method determines the place of the input in the form
 *
 * @param {object} field the field descriptor
 * @param {'right'|'left'|undefined} [field.placement] the side field placement in the form
 * @param {string} field.kind the type of the field
 *
 * @return {'right'|'left'|string} the field placement
 */
export function getFieldPlacement(field) {
    if (field.placement) {
        return field.placement;
    } else if (field.kind === 'external') {
        return 'right';
    } else {
        return 'left';
    }
}

/**
 * Builds the attributes for a control
 *
 * @param {FieldDescriptor} control the control to build the attributes for
 * @param {ControlConfig} config the control's configuration
 * @param {FieldDescriptor} [overwrites = {}] extra attributes to add to the control
 *
 * @returns {object} the attributes for the control
 */
export function getControlAttributes(control, config, overwrites = {}) {
    // Apply defaults
    const attrs = {
        name: control.field,
        disabled: control.disabled,
        placeholder: control.placeholder || control.example,
        hasDefault: control.hasOwnProperty('default'),
        legacy: control,
        hint: control.hint
    };

    if (!config.noTip) {
        attrs.tip = control.tooltip;
    }

    if (!config.noLabel) {
        attrs.label = control.label || capitalize(humanize(control.field));

        if (get(control, 'validations.required')) {
            attrs.label = `${attrs.label} *`;
        }
    }

    // Decoration
    decorateWithKindSpecificAttributes(control, config, attrs);
    decorateWithControlOptionsAttributes(control, config, attrs);

    if (control.validations?.maxlength) {
        attrs.maxlength = control.validations.maxlength;
    }
    // Apply overwrites
    Object.assign(attrs, overwrites);

    // Cleanup
    removeNilValues(attrs);

    return attrs;
}

/**
 * Decorates the control with kind-specific attributes based on the control's kind.
 *
 * @param {FieldDescriptor} control the control object.
 * @param {ControlConfig} config the configuration object.
 * @param {object} attrs the attributes object to be decorated.
 */
function decorateWithKindSpecificAttributes(control, config, attrs) {
    if (control.kind === 'timestamp') {
        attrs = Object.assign(attrs, {
            'format': DEFAULT_DATETIME_FORMAT,
            'minute-step': 10,
            'show-second': false,
            'tip': control.tooltip || config.$i18n.t('generic_form.types.timestamp'),
            'type': 'datetime',
            'value-type': 'X'
        }, control.kind_options || {});

        if (typeof config.value === 'number' && config.value > 9999999999) {
            attrs.valueType = 'x';
        }
    }

    if (control.kind === 'number') {
        attrs.type = 'number';
    }

    if (control.kind === 'colour') {
        attrs.context = config.context;
    }

    if (control.kind === 'video-call') {
        attrs.session = config.parentModel;
        attrs.settings = config.settings;
        attrs.event = config.event;
    }

    if (control.kind === 'list') {
        attrs.event = config.event;
    }

    if (control.kind === 'external') {
        attrs.options = [];
        attrs.fpType = control.kind_options.type;
        attrs.eventId = config.event?._id;
    }

    if (control.kind === 'custom') {
        attrs.model = config.parentModel;
        attrs.event = config.event;
    }
}

/**
 * Decorates a control with control options attributes.
 *
 * @param {FieldDescriptor} control the control object to decorate.
 * @param {ControlConfig} config the configuration object.
 * @param {object} attrs the attributes object to decorate.
 */
function decorateWithControlOptionsAttributes(control, config, attrs) {
    const options = control.kind_options;
    if (!options) return;

    const { values, values_order } = options;

    if (values) {
        attrs.options = Object.keys(values).map(v => ({ label: values[v], value: v }));
        attrs.trackBy = 'value';

    } else if (options.fields) {
        attrs.fields = options.fields;

    } else {
        attrs.options = options;
    }

    if (options.list_style === 'select') {
        attrs.trackBy = 'value';
    }

    if (options.format) {
        attrs.format = options.format;
    }

    if (options.type === 'targets-exceptions') {
        attrs.expandable = false;
    }

    if (options.id_field) {
        attrs.emitKey = options.id_field;
        attrs.valueKey = ['fp_asset', '_id'].includes(options.id_field) ? 'ids' : `${options.id_field}s`;
    }

    if (values_order) {
        attrs.options.sort((a, b) => values_order[a.value] - values_order[b.value]);
    }

    if (control.kind === 'text-multiline') {
        attrs = { ...attrs, ...options };
        delete attrs.options;
    }

    if (control.kind === 'timestamp') {
        if (options.timeInterval) {
            const interval = get(config.settings, options.timeInterval.replace(/^settings\./, ''));
            if (interval) {
                attrs.minuteStep = interval / 60;
            }
        }
    }

    if (control.kind === 'list') {
        attrs.itemType = options.item_kind;
    }

    if (control.kind === 'external') {
        attrs.multiple = !options.single_doc;
    }
}

/**
 * Cleans the attributes object from nil values.
 *
 * @param {object} attrs the attributes object to remove nil values from
 */
function removeNilValues(attrs) {
    Object.keys(attrs).forEach(key => {
        if (isNil(attrs[key])) {
            delete attrs[key];
        }
    });
}


/**
 * Retrieves the validators for a given control.
 *
 * @param {FieldDescriptor} control - The control object.
 * @param {Object} [dependencies] - The validator's dependencies object.
 *
 * @returns {string} - The validators for the control.
 */
export function getControlValidators(control, dependencies) {
    if (control.kind === 'colour') {
        control.validations = control.validations || {};
        control.validations['hex_color'] = true;
    }

    if (control.kind === 'custom') {
        const validators = {};
        for (const validator of Object.keys(control.validations || {})) {
            validators[validator] = dependencies || true;
        }
        return Object.keys(validators).length ? validators : control.validations || '';
    }

    return Object.keys(control.validations || {}).reduce((acc, k) => {
        if (k === 'pattern') return acc;
        if (acc.length) acc += '|';
        if (k === 'required') {
            acc += k;
        } else if (control.kind === 'number' && (k === 'max' || k === 'min')) {
            acc += `${k}_value:${control.validations[k]}`;
        } else if (control.kind === 'text' && k === 'maxlength') {
            acc += `max:${control.validations[k]}`;
        } else {
            acc += `${k}:${control.validations[k]}`;
        }
        return acc;
    }, '');
}

/**
 * Returns a document of type fp_type where all values are the default values.
 *
 * @param {FieldDescriptor} fields the fields to extract the defaults from
 *
 * @return {Object} the document with the default values
 */
export function extractDefaults(fields, $services) {
    const defaults = {};

    for (const [key, value] of Object.entries(fields)) {
        if (!value) {
            continue;
        }

        const field = value.field || key;

        if (value.kind === 'custom') {
            defaults[field] = value.hasOwnProperty('default') ? value.default : [];
            continue;
        }

        if (value.kind === 'choice' && value.kind_options?.list_style === 'multi-choice') {
            defaults[field] = value.default;
            continue;
        }

        if (
            value.kind !== 'nested-object' ||
            !isEmpty(value.default) ||
            !value.kind_options?.fields
        ) {
            defaults[field] = clone(value.default);
            continue;
        }

        const optionsFields = value.kind_options.fields;

        if (optionsFields) {
            const nestedDefaults = extractDefaults(optionsFields, $services);
            defaults[field] = nestedDefaults;
        }
    }

    const processedDefaults = {};

    for (const [field, defaultValue] of Object.entries(defaults)) {
        const processedValue = replacePlaceholdersInTree(defaultValue, $services);
        processedDefaults[field] = processedValue;
    }

    return processedDefaults;
}

/**
 * Replaces placeholders in a tree-like structure with generated values.
 *
 * @param {any} val - The value to process.
 * @param {Services} $services - The services object.
 *
 * @returns {any} - The processed value with placeholders replaced.
 */
export function replacePlaceholdersInTree(val, $services) {
    if (!val) {
        return val;
    }

    const genRegEx = new RegExp(GENERATED_DEFAULT_VALUE_PLACEHOLDER, 'g');

    if (genRegEx.exec(val)) {
        return val.replace(genRegEx, $services.documents.generateAutoGeneratedValueString());
    }

    // handle object recursively
    if (isObject(val) && !Array.isArray(val)) {
        Object.entries(val).forEach((value, key) => val[key] = replacePlaceholdersInTree(value));
    }

    return val;
}

/**
 * Parses a server error from a string.
 *
 * @param {import('axios').AxiosError} error - The Axios error string to parse.
 *
 * @returns {ParsedServerError} - The formatted error object.
 */
export function parseServerError(error) {
    const errorString = get(error, 'data.error');
    const genericError = { detail: 'generic_form.messages.save_failed' };

    if (typeof errorString !== 'string') {
        console.warn('[utils/controls] Server did not returned an error string:', errorString);
        return genericError;
    }

    try {
        const parsedError = JSON.parse(errorString);
        if (parsedError.stack) {
            const stack = parsedError.stack.replaceAll('.appscript.vm', '').replaceAll(/:(\d+):(\d+)/g, ' at line $1:$2');
            console.error('Server responded with an error:', stack);
        } else {
            console.debug('[utils/controls] Parsed server error', parsedError);
        }

        return genericError;
    } catch (e) { /* Not a JSON string, continue */ }

    try {
        const [idField, id, message] = errorString.split(':').map(s => s.trim());
        const field = message?.substring(0, message.indexOf(' '));
        const detail = message?.substring(message.indexOf(' ') + 1);
        const parsedError = { idField, id, field, detail };

        if (!parsedError.detail) {
            return genericError;
        }

        console.debug('[utils/controls] Parsed server error', errorString, parsedError);
        return parsedError;

    } catch (e) {
        console.warn('[utils/controls] Failed to parse server error', errorString, e);
        return { detail: errorString };
    }
}
