"use strict";

module.exports = function (options) {
  var resolverOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var resolver = /* @ngInject */function resolver($route, appScriptService) {
    var appscriptUrl = options.appscriptUrl,
      _options$params = options.params,
      params = _options$params === void 0 ? {} : _options$params,
      routeParam = options.routeParam;
    var returnData = resolverOptions.returnData;
    if (!appscriptUrl) {
      throw Error('an appscriptUrl must be provided for this strategy');
    }

    // extract a value from a route parameter is routeParam is specified,
    // and provide it as id
    if (routeParam) {
      _.extend(params, {
        id: $route.current.params[routeParam]
      });
    }
    return appScriptService.callAppScript(appscriptUrl, params, returnData);
  };
  resolver.$inject = ["$route", "appScriptService"];
  return resolver;
};