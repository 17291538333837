/* eslint-disable
   eqeqeq,
   no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
const _ = require('underscore');

angular.module('backstage.services').factory('modalService', [
    '$rootScope',
    '$q',
    '$compile',
    '$templateRequest',
    '$document',
    function($rootScope, $q, $compile, $templateRequest, $document) {

        // an assumption here is that "mid" modals are not closeable
        let body = angular.element($document).find('body');
        let modalTemplate = (template, cssClasses) =>
            `<div strap-modal
                 ng-mousewheel="onMouseWheel($event)"
                 show-modal-when="$$showModal"
                 ng-class="$$modalConfig.cssClasses"
                 class="modal-service">
                     ${template}
            </div>`;
        let openModalTokens = [];

        const openedModals = (function() {
            const modals = [];

            return {
                add: modal => modals.push(modal),
                remove: modal => {
                    const index = modals.indexOf(modal);

                    if (index !== -1) {
                        modals.splice(index, 1);
                    }

                    angular.element('html').removeClass('modal-open');
                },
                getAll: () => modals
            };
        }());

        // # # # #
        // public
        // # # # #

        return {
            openModal(config) {
                let id = _.uniqueId('modal');
                let deferred = $q.defer();

                let modalElement = null;
                let scope = $rootScope.$new(true);
                let promise = deferred.promise;

                openedModals.add(promise);

                _.extend(scope, config.scope || {});
                _.extend(scope, {
                    $$modal: deferred,
                    $$showModal: true,
                    $$modalConfig: config
                });

                let getTemplate = function() {
                    if (config.templateUrl) { return $templateRequest(config.templateUrl); }
                    if (config.template) { return $q.when(config.template); }
                };

                let selfDestruct = function() {
                    // we're hiding the element first so that strapModal directive
                    // knows it can hide the backdrop if this was the only div
                    if (modalElement) {
                        modalElement.hide();
                    }
                    scope.$destroy();
                    promise.isOpen = false;
                    openedModals.remove(promise);
                    return modalElement && modalElement.remove();
                };

                getTemplate()
                    .then(function(template) {
                        modalElement = $compile(modalTemplate(template, config.cssClasses))(scope);
                        // magic
                        return body.append(modalElement);
                        // unmagic
                    });

                promise
                    .then(function(reason) {
                        scope.$$showModal = false;
                        selfDestruct();
                        return reason;
                    })
                    .catch(function(reason) {
                        scope.$$showModal = false;
                        selfDestruct();
                        return $q.resolve(reason || 'modal-closed');
                    });

                promise.close = selfDestruct;
                promise.isOpen = true;

                angular.element('html').addClass('modal-open');

                return promise;
            },

            /**
             * @param {String} [options.title]
             * @param {String} [options.message]
             * @param {String} [options.okLabel]
             * @param {String} [options.cancelLabel]
             * @param {any} options.scope
             * @param {String} optison.defaultValue}
             * @return {ng.IPromise<void>} resolved with the value provided by user
             */
            openPrompt(options) {
                let {
                    title = 'Modal',
                    message = '',
                    okLabel = 'OK',
                    cancelLabel = 'Cancel',
                    scope = {},
                    defaultValue
                } = options;

                scope.model = defaultValue;

                const header = `\
                            <div class="modal-header">
                                    <a href="#" class="close" ng-click="$$modal.reject()">&times;</a>
                                    <h3>${title}</h3>
                            </div>`;

                return this.openModal({
                    template: `\
                            ${title ? header : ''}
                            <form class="modal-body form" ng-submit="$$modal.resolve(model)">
                                <label for="modal-text-input">${message}</label>
                                <input type="text" ng-model="model" id="modal-text-input"/>
                            </form>
                            <div class="modal-footer">
                                <input type="button"
                                    class="btn btn-glow"
                                    value="${cancelLabel}"
                                    ng-click="$$modal.reject()" />
                                <input type="button"
                                    class="btn btn-glow primary"
                                    value="${okLabel}"
                                    ng-click="$$modal.resolve(model)"/>
                            </div>\
                        `,
                });
            },

            /**
             * @param {String} [options.title]
             * @param {String} [options.message]
             * @param {String} [options.okLabel]
             * @param {String} [options.cancelLabel]
             * @param {any} options.scope
             * @return {ng.IPromise<void>} resolved only if "Yes" was clicked
             */
            openConfirm(options) {
                return this.openAlert(_.extend({
                    title: 'Confirm',
                    message: '',
                    okLabel: 'OK',
                    cancelLabel: 'Cancel'
                }, options));
            },

            /**
             * @param {String} [options.title]
             * @param {String} [options.message]
             * @param {String} [options.okLabel]
             * @param {String} [options.cancelLabel]
             * @param {any} options.scope
             * @return {ng.IPromise<void>}
             */
            openAlert(options) { // @ts-check
                let {
                    title = 'Alert',
                    message,
                    okLabel = 'OK',
                    cancelLabel,
                    scope = {}
                } = options;

                const cancelButton = `\
                        <input type="button"
                                    class="btn btn-glow"
                                    value="${cancelLabel}"
                                    ng-click="$$modal.reject()" />`;

                const header = `\
                            <div class="modal-header">
                                    <a href="#" class="close" ng-click="$$modal.reject()">&times;</a>
                                    <h3>${title}</h3>
                            </div>`;

                return this.openModal({
                    template: `\
                            ${title ? header : ''}
                            <div class="modal-body">
                                <p>${message}</p>
                            </div>
                            <div class="modal-footer">
                                ${cancelLabel ? cancelButton : ''}
                                <input type="button"
                                    class="btn btn-glow primary"
                                    value="${okLabel}"
                                    ng-click="$$modal.resolve()"/>
                            </div>\
                        `,
                    scope
                });
            },

            openModalWithForm({
                title,
                message,
                messageClassNames,
                model,
                fields,
                saveLabel,
                formCanBeSaved,
                onSaveClick,
                hasCancelButton,
                cancelLabel
            }) {
                if (!message) { message = ''; }
                if (!messageClassNames) { messageClassNames = ''; }
                if (!saveLabel) { saveLabel = 'Save'; }
                if (!formCanBeSaved) { formCanBeSaved = (model, form) => form.$valid; }
                if (!onSaveClick) { onSaveClick = () => {}; }

                return this.openModal({
                    scope: {
                        form: {},
                        model: model || {},
                        fields,
                        formCanBeSaved,
                        onSaveClick() {
                            return $q.resolve(onSaveClick())
                                .then(() => this.$$modal.resolve(this.model, this.form));
                        }
                    },
                    template: `\
                            <div class="modal-header">
                                <a href="#" class="close" ng-click="$$modal.reject()">&times;</a>
                                <h3>${title}</h3>
                            </div>
                            <div class="modal-body">
                                <p class="${messageClassNames}">${message}</p>
                                <form name="form"
                                        data-bs-form-generator
                                        data-form="form"
                                        data-model="model"
                                        data-fields="fields"
                                        data-omit-button-bar="true"
                                        autocomplete="off"></form>
                            </div>
                            <div class="modal-footer">
                                <input type="button"
                                    ng-if="${hasCancelButton}"
                                    class="btn btn-glow"
                                    ng-click="$$modal.reject()"
                                    value="${cancelLabel}"/>
                                <input type="button"
                                        class="btn btn-glow primary"
                                        ng-click="onSaveClick()"
                                        ng-disabled="!formCanBeSaved(model, form)"
                                        value="${saveLabel}"/>
                            </div>`
                });
            },

            openModalWithComponent({ title, component, args, saveLabel }) {
                if (!saveLabel) saveLabel = 'Save';
                if (!args) args = {};

                const attributes = _.map(args, (_v, key) => `${key}="args.${key}"`)
                    .join(' ');

                const scope = {
                    args,

                    serialize: () => scope.args.model,
                    setSerializeFn: ({ serialize }) =>
                        scope.serialize = serialize,

                    save() {
                        $q.when(scope.serialize()).then(this.$$modal.resolve);
                    }
                };

                return this.openModal({
                    scope: scope,
                    template: `\
                        <div class="modal-header">
                            <a href="#" class="close" ng-click="$$modal.reject()">&times;</a>
                            <h3>${title}</h3>
                        </div>
                        <div class="modal-body">
                            <${component} modal="$$modal"
                                modal-register-serialize="setSerializeFn($event)"
                                ${attributes}></${component}>
                        </div>
                        <div class="modal-footer">
                            <input type="button"
                                    class="btn btn-glow primary"
                                    ng-click="save()"
                                    value="${saveLabel}"/>
                        </div>`
                });
            },

            trackModalOpened(token) {
                openModalTokens.push(token);
            },

            trackModalClosed() {
                if (openModalTokens.length) { openModalTokens.pop(); }
            },

            getOpenModalCount() {
                return openModalTokens.length;
            },

            isModalContainingNestedModals(token) {
                return (openModalTokens.length > 1) && (_.last(openModalTokens) !== token);
            },

            isSoleOpenModal(token) {
                return (openModalTokens.length === 1) && (openModalTokens[0] === token);
            },

            closeAll() {
                openedModals.getAll().forEach(modal => modal && modal.close());
            }
        };
    }
]);
