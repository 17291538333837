import BaseService from './base-service';

// Constants
import { API_BASE_PATH, CENTRAL_BACKSTAGE_URL, USER_PROFILE_ENDPOINT, USER_PASSWORD_ENDPOINT } from 'libs/utils/constants';

/**
 * @const {string} SEND_USER_PASS_TOKEN_ENDPOINT
 * @private
 */
const SEND_USER_PASS_TOKEN_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/send-password-reset-token`;

/**
 * @const {string} VALIDATE_ACCOUNT_ENDPOINT
 * @private
 */
const VALIDATE_ACCOUNT_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/users/validate-account`;

/**
 * @const {string} CONSUME_USER_PASS_TOKEN_ENDPOINT
 * @private
 */
const CONSUME_USER_PASS_TOKEN_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/consume-password-reset-token`;

/**
 * @const {string} REQUEST_ACCOUNT_VALIDATION_ENDPOINT
 * @private
 */
const REQUEST_ACCOUNT_VALIDATION_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/users/request-account-validation`;

/**
 * Provides utils for account management.
 *
 * @example
 * import AccountService from 'libs/services/account';
 * ...
 * const Account = new AccountService();
 */
export default class AccountService extends BaseService {

    /**
     * Updates user data with the given parameters
     *
     * @param {Object} params the user's changing fields object
     *
     * @returns {Promise<Object>} server response
     */
    async updateUser(params = {}) {
        const { data } = await this.post(USER_PROFILE_ENDPOINT, params, { withCredentials: true });

        return data;
    }

    /**
     * Updates user's password
     *
     * @param {String} oldPassword the old password
     * @param {String} newPassword the new password
     *
     * @returns {Promise<Object>} server response
     */
    async changePassword(oldPassword, newPassword) {
        const { data } = await this.post(USER_PASSWORD_ENDPOINT, {
            oldPassword, newPassword
        }, { withCredentials: true });

        return data;
    }

    /**
     * Sends a reset password token to the specified email address
     *
     * @param {string} email the email to which send the reset token
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    sendPasswordRecoveryToken(email) {
        return this.post(SEND_USER_PASS_TOKEN_ENDPOINT, { email }, { withCredentials: true });
    }

    /**
     * Validates the email with the given token
     *
     * @param {string} email the user email to validate
     * @param {string} token the token used for the validation
     *
     * @returns {Promise<string>} promise for user name
     */
    async validateEmailAndToken(email, token) {
        const { data } = await this
            .get(VALIDATE_ACCOUNT_ENDPOINT, {
                params: { email: encodeURIComponent(email), token },
                // we need this because otherwise session interceptor would
                // be in an indefinite loop:
                isSessionCall: true
            });

        return data.name;
    }

    /**
     * Validates the account with the given parameters.
     *
     * @param {object} params
     *
     * @returns {Promise<object>} the response data
     */
    async validate(params) {
        const { data } = await this.post(VALIDATE_ACCOUNT_ENDPOINT, params, { withCredentials: true });

        return data;
    }

    /**
     * Given a password recovery token this mehtod sets a new password for
     * the specified email.
     *
     * @param {string} token
     * @param {string} email
     * @param {string} password
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    resetPassword(token, email, password) {
        return this.post(CONSUME_USER_PASS_TOKEN_ENDPOINT, {
            email,
            password,
            token
        }, { withCredentials: true });
    }

    /**
     * Sends a request for a new account activation link
     *
     * @param {string} email the email to validate the account for
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    requestNewValidation(email) {
        return this.post(REQUEST_ACCOUNT_VALIDATION_ENDPOINT, { email }, { withCredentials: true });
    }

}
