"use strict";

emailTemplateEditorController.$inject = ["$q", "$timeout", "$scope", "modalService", "metadataService", "eventService", "packagesService"];
var _ = require('underscore');
var QR_SERVER_URL = 'https://api.qrserver.com/v1/create-qr-code/';
function emailTemplateEditorController($q, $timeout, $scope, modalService, metadataService, eventService, packagesService) {
  var currentEvent = eventService.getCurrentEvent();
  var eventId = currentEvent._id;
  var personMetadata = [];
  var isPostEventInstalled = false;

  // utils
  var nodeIsQrCode = function nodeIsQrCode(node) {
    return node && node.nodeName === 'IMG' && node.src && node.src.indexOf(QR_SERVER_URL) !== -1;
  };
  var extractModelFromUrl = function extractModelFromUrl(url) {
    var queryParams = _.chain(url.split('?')[1].split('&')).map(function (param) {
      var parts = param.split('=');
      return [parts[0], decodeURIComponent(parts.slice(1).join('='))];
    }).object().value();
    var size = parseInt((queryParams.size || '').split('x')[0], 10);
    return {
      qrData: queryParams.data || '',
      size: _.isNumber(size) ? size : 150,
      colour: '#' + (queryParams.color || '000000')
    };
  };
  var generateInserter = function generateInserter(editor, text) {
    return function () {
      return editor.insertContent(text);
    };
  };

  // custom tinymce button initializers
  // alignment -- collapses text alignment options into a dropdown
  var addAlignmentButton = function addAlignmentButton(editor) {
    editor.addButton('alignment', {
      type: 'menubutton',
      icon: 'alignleft',
      tooltip: 'Alignment',
      menu: [{
        icon: 'alignleft',
        text: 'Left',
        onclick: function onclick() {
          return editor.execCommand('JustifyLeft');
        }
      }, {
        icon: 'aligncenter',
        text: 'Center',
        onclick: function onclick() {
          return editor.execCommand('JustifyCenter');
        }
      }, {
        icon: 'alignright',
        text: 'Right',
        onclick: function onclick() {
          return editor.execCommand('JustifyRight');
        }
      }, {
        icon: 'alignjustify',
        text: 'Justify',
        onclick: function onclick() {
          return editor.execCommand('JustifyFull');
        }
      }]
    });
  };

  // placeholder -- dropdown with {{code}}/{{sso}}/{{_webapp_link}} + all pax fields
  var addPlaceholdersButton = function addPlaceholdersButton(editor) {
    var metadataFieldsAsMenu = _.map(personMetadata, function (descriptor) {
      return {
        text: descriptor.label || descriptor.field,
        onclick: generateInserter(editor, '{{' + descriptor.field + '}}')
      };
    });
    editor.addButton('placeholders', {
      type: 'menubutton',
      icon: 'codesample',
      tooltip: 'Insert placeholder fields',
      menu: [{
        text: 'Event name',
        onclick: generateInserter(editor, '{{_event_name}}')
      }, {
        text: 'Install link',
        onclick: generateInserter(editor, '<a href="https://install.events/{{_app}}">install.events/{{_app}}</a>')
      }, {
        text: 'Privacy policy link',
        onclick: generateInserter(editor, '{{_privacy_link}}')
      }, {
        text: 'Calendar links',
        onclick: generateInserter(editor,
        // without mc:disable-tracking it does not work on chrome+mac
        // for some reason content-disposition after 302 is broken on chrome on mac
        '<a href="{{_calendar_ical_link}}" mc:disable-tracking>Add to calendar</a> | ' + '<a href="{{_calendar_google_link}}" mc:disable-tracking>Add to Google Calendar</a>')
      }, {
        text: 'Personalised links',
        menu: [{
          text: 'Web App link',
          onclick: generateInserter(editor, '<a href="{{_webapp_link}}">Web App Link</a>')
        }, {
          text: 'Registration link',
          onclick: generateInserter(editor, '<a href="{{_registration_link}}">Registration Link</a>')
        }].concat(isPostEventInstalled ? {
          text: 'Post-event website link',
          onclick: generateInserter(editor, '<a href="{{_sso}}">Post-event link</a>')
        } : [])
      }].concat(metadataFieldsAsMenu.length ? {
        text: 'Participant details',
        menu: metadataFieldsAsMenu
      } : [])
    });
  };

  // advanced -- dropdown with random tools that didn't fit in main toolbar
  var addAdvancedButton = function addAdvancedButton(editor) {
    editor.addButton('advanced', {
      type: 'menubutton',
      icon: 'options',
      tooltip: 'Advanced tools',
      menu: [{
        icon: 'qr',
        text: 'Insert QR code',
        onclick: function onclick() {
          $timeout(function () {
            var selection = editor.selection.getNode();
            var mode = nodeIsQrCode(selection) ? 'edit' : 'insert';
            modalService.openModal({
              templateUrl: '/api/v1/bstg-template/emailing-qr-modal.html',
              scope: {
                mode: mode,
                model: mode === 'insert' ? {
                  qrData: "http://install.events/".concat(currentEvent.branded_app || 'eventspace', "?code={{code}}"),
                  size: 150,
                  colour: '#000000'
                } : extractModelFromUrl(selection.src)
              }
            }).then(function (model) {
              var data = encodeURIComponent(model.qrData).replace(/\%7B/gi, '{').replace(/\%7D/gi, '}');
              var size = "".concat(model.size, "x").concat(model.size);
              var colour = model.colour.slice(1);
              var qrUrl = "".concat(QR_SERVER_URL, "?size=").concat(size, "&data=").concat(data, "&color=").concat(colour);
              if (mode === 'insert') {
                editor.insertContent("<img src=\"".concat(qrUrl, "\"/>"));
              } else {
                selection.src = qrUrl;
              }
            });
          });
        }
      }, {
        icon: 'removeformat',
        text: 'Remove formatting',
        onclick: function onclick() {
          return editor.execCommand('RemoveFormat');
        }
      }, {
        icon: 'code',
        text: 'Source code',
        onclick: function onclick() {
          return editor.execCommand('mceCodeEditor');
        }
      }, {
        icon: 'fullscreen',
        text: 'Toggle fullscreen',
        onclick: function onclick() {
          return editor.execCommand('mceFullscreen');
        }
      }]
    });
  };
  var editorSetup = function editorSetup(editor) {
    addAlignmentButton(editor);
    addPlaceholdersButton(editor);
    addAdvancedButton(editor);
  };
  $scope.vm = {
    ready: false,
    tinymceConfig: {
      setup: editorSetup
    }
  };
  $q.all({
    metadata: metadataService.getCachedMetadataForTypeAsArray(eventId, 'person'),
    installedPackages: packagesService.getInstalledPackages(eventId, currentEvent.node)
  }).then(function (data) {
    personMetadata = data.metadata;
    isPostEventInstalled = 'postevent' in data.installedPackages.data;
    $scope.vm.ready = true;
  });
}
angular.module('backstage.controllers.widgets').controller('EmailTemplateEditor', /* @ngInject */emailTemplateEditorController);